interface AuthConfig {
  clientID: string;
  domain: string;
  callbackURL: string;
  audience: string;
}
export const AUTH_CONFIG: AuthConfig = {
  clientID: 'RJS3nZvqMA4rEVUVozV7UjOv6gpumZ9Y',
  domain: 'auth.voiply.online',
  callbackURL: location.origin + "/callback",
  audience: 'https://voiply.online'
};
