<div class="row border-0 bg-primary-gradient" style="">
  <div class="col-4 d-flex align-items-center">
    <img src="assets/images/logo_white_full.png" height="45px" />
  </div>
  <div class="col-6 col-md-4  d-flex align-items-center justify-content-center ">
    <img src="assets/images/user-settings.png" height="20px" class="pr-2" />
    <h3 class="text-primary m-0 font-weight-bold py-4">
      User Settings
    </h3>
  </div>
  <div class="col-4  d-none d-xl-block">
    <div class="d-flex align-items-center justify-content-end py-3 ">
      <div class="flex-column " *ngIf="checkoutDetail">
        <div class="text-right font-weight-bold text-primary">{{this.checkoutDetail.companyName}}</div>
        <div class="text-right  text-primary">{{this.checkoutDetail.billingAddress.address}} -
          {{this.checkoutDetail.billingAddress.city}} - {{this.checkoutDetail.billingAddress.zip}}
        </div>
      </div>
      <img [src]="this.userProfile.picture" alt="" class="rounded-circle ml-3 " height="45px" />
      <!-- <hr class="mx-3 border-right" style="height: 35px;" />
    <i class="far fa-sign-out text-primary fa-lg cursor-pointer" (click)="onLogout()"></i> -->
    </div>
  </div>
</div>