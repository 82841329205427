import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { FillOrderAction, UserIsVoiplyTeamMemberAction, OrderService, UserService, ENVIRONMENT, IEnvironment } from '@voiply/shared/common-ui';
import { SystemType } from '@voiply/shared/model';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsAuthGuard implements CanActivate {


  @Dispatch() saveOrderState = (orderData: any) => new FillOrderAction(orderData);
  @Dispatch() userIsVoiplyTeamMember = (isTrue) => new UserIsVoiplyTeamMemberAction(isTrue);
  constructor(private router: Router, private auth: AuthService, private orderService: OrderService, private userService: UserService, @Inject(ENVIRONMENT) private environment: IEnvironment) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (route.queryParamMap.get('tab'))
      localStorage.setItem('selectedMatTab', route.queryParamMap.get('tab'));
    if (this.auth.isAuthenticated()) {
      let user = [];
      user = JSON.parse(localStorage.getItem('users'));
      if ((user || []).length > 0) {
        const orderId = user[0].organization_id;
        const order: any = await this.orderService.getOrderAsync(orderId);
        if (order) {
          if (order.metadata.systemType) {
            this.environment.systemType = order.metadata.systemType;
          } else if (order.metadata.site === 'https://build.voiply.house' || order.metadata.site === 'res.voiply.dev') {
            this.environment.systemType = SystemType.Home;
          } else if (order.metadata.site === 'https://build.voiply.com' || order.metadata.site === 'biz.voiply.dev') {
            this.environment.systemType = SystemType.Business;
          } else {
            this.environment.systemType = SystemType.Business;
          }
          order.orderId = orderId;
          this.saveOrderState(order);
        } else {
          alert('No Order Found with this ID');
        }
      } else {
        this.auth.login('', false, '');
      }
      return true

    } else {
      this.auth.checkSession();
    }
  }
}
