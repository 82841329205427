import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '../environments/environment';
import { ProductModule } from '@voiply/product';
import { CommonUiModule, ENVIRONMENT } from '@voiply/shared/common-ui';
import { Auth0UserSettingsModule } from '@voiply/auth0-user-settings';
import { RouterModule } from '@angular/router';

import { AppRoutes } from './app.routing';

import { AppComponent } from './app.component';
import { RootPageComponent } from './root-page/root-page.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [AppComponent, RootPageComponent, FooterComponent, HeaderComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes, { initialNavigation: 'enabled' }),
    // State
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),

    CommonUiModule,
    Auth0UserSettingsModule,
    ProductModule,

  ],
  providers: [{ provide: ENVIRONMENT, useValue: environment }],
  bootstrap: [AppComponent]
})
export class AppModule { }
