<!--containing in - Lib: product-configuration -->
<!--used by - Container: product-configuration-user-settings -->
<!--Added for - App: user-settings-->
<form [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()">
    <!-- <div class="d-flex flex-row justify-content-center p-3 bg-primary-gradient align-items-center">
    <h4 class="text-primary m-0">{{value.heading}}</h4>
  </div> -->
    <div class="">
        <div *ngIf="value.type==='phone'">
            <div formGroupName={{key}}>
                <voiply-home-phone-form-control [submitted]="formSubmitted" formControlName="phone"
                    (isHideConfig)=onHideConfig($event)>
                </voiply-home-phone-form-control>

                <mat-tab-group #tabs animationDuration="0ms" class="cart-item-background-color"
                    [hidden]="isHideConfiguration===true" [selectedIndex]='selectedMatTab-1'>
                    <mat-tab label="VoiceMail Settings">
                        <voiply-missed-call-form-control formControlName="ringCount"></voiply-missed-call-form-control>
                        <voiply-voicemail-attachment-form-control formControlName="voicemailAttachment">
                        </voiply-voicemail-attachment-form-control>

                        <voiply-voicemail-form-control formControlName="voice" insertTemplateVariableFrom="{{key}}"
                            audioCategory="VoiceMail" [recordingData]="productConfig">
                        </voiply-voicemail-form-control>
                    </mat-tab>

                    <mat-tab label="Call Forwarding Settings">
                        <voiply-callforwarding-form-control formControlName="callForwarding">
                        </voiply-callforwarding-form-control>
                    </mat-tab>
                    <mat-tab label="Device Settings" *ngIf="value.itemId===11||userIsVoiplyMember">
                        <voiply-device-settings-form-control formControlName="deviceSettings" [models]="models"
                            [submitted]="formSubmitted" [disableDeviceSettings]="disableDeviceSettings"
                            [showDeviceSettingsValidation]="showDeviceSettingsValidation">
                        </voiply-device-settings-form-control>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>

        <div *ngIf="value.heading==='Online Fax'">
            <div formGroupName={{key}}>
                <voiply-online-fax-form-control [submitted]="formSubmitted" [disableInput]="disableInput"
                    formControlName="onlineFax">
                </voiply-online-fax-form-control>
            </div>
        </div>
        <div *ngIf="value.heading==='Spam Call Bot'">
            <div formGroupName={{key}}>
                <voiply-spam-call-bot-form-control [submitted]="formSubmitted" formControlName="spamCallBot"
                    [blockedNumberList]="productConfig.blockedList || []"
                    [allowedNumberList]="productConfig.allowedList || []" [extDisabled]="extDisabled">
                </voiply-spam-call-bot-form-control>
            </div>
        </div>

        <div *ngIf="value.heading==='Emergency 999'">
            <div formGroupName="{{key}}">
                <voiply-alert911-form-control formControlName="emergency999"></voiply-alert911-form-control>
            </div>
        </div>
    </div>

    <div class="cart-item-background-color border p-3 d-flex flex-row justify-content-end">
        <button type="submit" class="btn btn-primary text-primary px-4 py-3 font-weight-bold">
            Save Changes
        </button>
    </div>
</form>
