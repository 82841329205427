import { Component, OnInit, Input } from '@angular/core';
import { HomeConfigurationBase } from '../home-configuration.base';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '@voiply/shared/common-ui';
import { SystemType } from '@voiply/shared/model';

@Component({
  selector: 'voiply-user-settings-home',
  templateUrl: './user-settings-home.component.html',
  styleUrls: ['./user-settings-home.component.scss']
})
export class UserSettingsHomeComponent extends HomeConfigurationBase {

  @Input() selectedMatTab = 1;

  constructor(toastr: ToastrService, orderService: OrderService) {
    super(toastr, orderService);
  }
  updateCartItemConfiguration(key, productConfig) {
    this.updateCartItemConfigurationAction(key, productConfig);
    if (this.value.paid) {
      this.orderService.updateOrder(this.orderId,SystemType.Home)
    }
  }

}
