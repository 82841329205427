import { Injectable, Output, EventEmitter, Inject, Directive } from '@angular/core';
import { AUTH_CONFIG } from '../auth0-variables';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Auth0LockPasswordless } from 'auth0-lock';
import * as auth0 from 'auth0-js';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT, IEnvironment } from '@voiply/shared/common-ui';



(window as any).global = window;

@Directive()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userId;
  @Output() errorEmitter: EventEmitter<any> = new EventEmitter();
  private options = {
    autoclose: true,
    closable: false,
    configurationBaseUrl: 'https://cdn.auth0.com',
    theme: {
      logo: 'https://voiply.online/assets/images/icons/168x168Logo.png',
      primaryColor: '#000',
    },
    languageDictionary: {
      title: 'Voiply Online',
      signUpTitle: 'Voiply Online',
      forgotPasswordAction: 'Forgot password?',
      submitLabel: 'Log In',
      success: {
        logIn: 'Intitial validation done, validating voiply.online account.'
      },
    },
    auth: {
      audience: AUTH_CONFIG.audience,
      redirectUrl: AUTH_CONFIG.callbackURL,
      responseType: 'token id_token',
      params: {
        scope: 'openid email profile'
      }
    },
    socialButtonStyle: 'big',
    allowedConnections: ['sms', 'facebook', 'google-oauth2', 'linkedin', 'windowslive'],
    passwordlessMethod: 'code',
    rememberLastLogin: false
  };
  private lock = new Auth0LockPasswordless(AUTH_CONFIG.clientID, AUTH_CONFIG.domain, this.options);
  constructor(public router: Router, private httpClient: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) { }

  public login(redirectUrl, first, userId): void {
    this.userId = userId;
    if (redirectUrl) {
      this.options.auth.redirectUrl = redirectUrl;
      if (first === true) {
        this.options.languageDictionary['signUpTitle'] = 'Order Confirmation';
        this.options.languageDictionary['title'] = 'Order Confirmation';
        this.options.languageDictionary['submitLabel'] = 'CONFIRM';
        this.options.languageDictionary['signUpTerms'] = 'Orders will not be processed until validation is completed.';
        // tslint:disable-next-line:max-line-length
        this.options.languageDictionary['passwordlessSMSAlternativeInstructions'] = 'Otherwise, enter your mobile number to complete the process.';
      } else {
        this.options.languageDictionary['signUpTitle'] = 'Voiply Portal';
        this.options.languageDictionary['title'] = 'Voiply Portal';
        this.options.languageDictionary['submitLabel'] = 'LOG IN';
        // tslint:disable-next-line:max-line-length
        this.options.languageDictionary['signUpTerms'] = 'By signing in, you agree to our <a href="https://www.voiply.com/terms" target="_blank">Terms of Service</a> and <a href="https://www.voiply.com/privacy-policy" target="_blank">Privacy Policy</a>';
        // tslint:disable-next-line:max-line-length
        this.options.languageDictionary['passwordlessSMSAlternativeInstructions'] = 'Otherwise, enter your mobile number to sign in.';
      }
    }
    if (this.userId) {
      this.options.auth.redirectUrl = `${this.options.auth.redirectUrl}?id=${this.userId}`;
    }

    // console.log('callback url:' + this.options.auth.redirectUrl);
    this.lock = new Auth0LockPasswordless(AUTH_CONFIG.clientID, AUTH_CONFIG.domain, this.options);
    this.lock.show();
    this.handleAuthentication();
  }

  // Call this method in app.component.ts
  // if using path-based routing
  // implicit = true for quick login and implicit=false for callback flow
  public handleAuthentication(): void {
    this.lock.on('authenticated', (authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        const user = this.getUser(authResult.idTokenPayload);
        this.signupUserSettings(user).subscribe(data => {
          if (data.length > 0) {
            localStorage.setItem('users', JSON.stringify(data));
            this.lock.hide();
            this.router.navigate(['/']);
          } else {
            console.log('error');
          }
        });

      }
    });
    this.lock.on('authorization_error', (err) => {
      this.login('', false, '');
      console.log(err);
      alert(`Error: ${err.error}. Check the console for further details.`);
    });
  }


  private setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public logout(): any {
    // Remove tokens and expiry time from localStorage
    localStorage.clear();
    this.webAuth.logout({
      returnTo: location.origin + '/logout',
      clientID: AUTH_CONFIG.clientID
    });

  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
  signupUserSettings(userinfo): Observable<any> {
    return this.httpClient.post(`${this.environment.rootApi}/singup-user-settings`, userinfo);
  }

  private getUser(payload): any {
    const picture = payload.sub.split('|')[1];
    const user = {
      family_name: payload.family_name,
      given_name: payload.given_name,
      name: payload.name,
      nickname: payload.nickname,
      picture: `https://graph.facebook.com/v2.5/${picture}/picture`,
      sub: payload.sub,
      email: payload.email
    };
    return user;
  }


  // tslint:disable-next-line:member-ordering
  public webAuth = new auth0.WebAuth({
    clientID: AUTH_CONFIG.clientID,
    domain: AUTH_CONFIG.domain,
    redirectUri: AUTH_CONFIG.callbackURL,
    scope: 'openid profile',
    responseType: 'token id_token'
  });


  public checkSession() {
    this.webAuth.checkSession({ prompt: 'none' }, function (error, authResult) {
      if (error) {
        console.log(error);
        if (error.code === 'consent_required' || error.code === 'interaction_required') {
          return this.webAuth.authorize();
        } else {
          this.login('', false, '');

        }
      } else {
        if (this.isAuthenticated()) {
          console.log(authResult);
          this.setSession(authResult);
          this.router.navigate(['/']);
        } else {
          this.login('', false, '');
        }
      }
    }.bind(this));
  }

  public getProfile(accessToken: any): Promise<any> {
    this.webAuth = new auth0.WebAuth({
      clientID: AUTH_CONFIG.clientID,
      domain: AUTH_CONFIG.domain,
      scope: 'openid email profile',
      audience: AUTH_CONFIG.audience,
      responseType: 'token id_token',
    });

    return new Promise<any>((resolve, reject) => {
      this.webAuth.client.userInfo(accessToken, (err, profile) => {
        if (profile) {
          return resolve(profile);
        } else {
          return resolve(null);
        }
      });
    });
  }
}
