<div class="page-container">
    <div class="content-wrap">
        <div class="container-fluid">
            <app-header></app-header>
        </div>
        <div class="container">

            <voiply-product-user-settings [userId]='userId' [systemType]='systemType' [selectedMatTab]='selectedMatTab'>
            </voiply-product-user-settings>
        </div>
    </div>
    <app-footer class="footer"></app-footer>
</div>