import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'voiply-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent {


  constructor(private route: ActivatedRoute, private auth: AuthService) {
    this.route.queryParams.subscribe(params => {
      this.auth.handleAuthentication();
      return false;
  });
  }

}
