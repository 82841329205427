<!--containing in - Lib: product-configuration -->
<!--used by - Container: product-configuration-user-settings -->
<!--Added for - App: User-settings-->
<form [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()">
    <!-- <div class="d-flex flex-row justify-content-center p-3 bg-primary-gradient align-items-center">
    <h4 class="text-primary m-0">{{value.heading}}</h4>
  </div> -->
    <div class="">
        <ng-container *ngIf="value.type==='phone'">
            <div formGroupName={{key}}>
                <voiply-phone-form-control [submitted]="formSubmitted" formControlName="phone" [extDisable]=extDisabled
                    [disableInput]="disableInput" (isHideConfig)=onHideConfig($event)>
                </voiply-phone-form-control>
                <ng-container>
                    <mat-tab-group animationDuration="0ms" #tabs class="cart-item-background-color"
                        [selectedIndex]='selectedMatTab-1' [hidden]="isHideConfiguration===true">
                        <mat-tab label="VoiceMail Settings">
                            <voiply-missed-call-form-control formControlName="ringCount">
                            </voiply-missed-call-form-control>
                            <voiply-voicemail-attachment-form-control formControlName="voicemailAttachment">
                            </voiply-voicemail-attachment-form-control>
                            <voiply-includein-company-directory-form-control
                                formControlName="includeinCompanyDirectory">
                            </voiply-includein-company-directory-form-control>
                            <voiply-voicemail-form-control formControlName="voice" insertTemplateVariableFrom="{{key}}"
                                class="cart-item-background-color" audioCategory="VoiceMail"
                                [recordingData]="productConfig">
                            </voiply-voicemail-form-control>
                        </mat-tab>

                        <mat-tab label="Call Forwarding Settings">
                            <voiply-callforwarding-form-control formControlName="callForwarding">
                            </voiply-callforwarding-form-control>
                        </mat-tab>
                        <mat-tab label="Emergency Address" *ngIf="value.paid &&showEmergencyAddress">
                            <voiply-emergency-address-form-control formControlName="emergencyAddress"
                                [filteredStates]="filteredStates" [submitted]="formSubmitted"
                                [disableInput]="this.form.controls[this.key]['controls']['emergencyAddress'].value.enableEmergencyAddress">
                            </voiply-emergency-address-form-control>
                        </mat-tab>
                        <mat-tab label="Device Settings" *ngIf="value.itemId===11||userIsVoiplyMember">
                            <voiply-device-settings-form-control formControlName="deviceSettings" [models]="models"
                                [submitted]="formSubmitted" [disableDeviceSettings]="disableDeviceSettings"
                                [showDeviceSettingsValidation]="showDeviceSettingsValidation">
                            </voiply-device-settings-form-control>
                        </mat-tab>

                    </mat-tab-group>

                </ng-container>
            </div>
        </ng-container>
        <div *ngIf="value.heading==='Online Fax'">
            <div formGroupName={{key}}>
                <voiply-online-fax-form-control [submitted]="formSubmitted" formControlName="onlineFax"
                    [disableInput]="disableInput">
                </voiply-online-fax-form-control>
            </div>
        </div>
        <div *ngIf="value.heading==='Conferencing'">
            <div formGroupName={{key}}>
                <voiply-conferencing-form-control [submitted]="formSubmitted" formControlName="conferencing"
                    [disableInput]="disableInput">
                </voiply-conferencing-form-control>
            </div>
        </div>
        <div *ngIf="value.heading==='Spam Call Bot'">
            <div formGroupName={{key}}>
                <voiply-spam-call-bot-form-control [submitted]="formSubmitted" formControlName="spamCallBot"
                    [extDisabled]="extDisabled" [blockedNumberList]="productConfig.blockedList || []"
                    [allowedNumberList]="productConfig.allowedList || []">
                </voiply-spam-call-bot-form-control>
            </div>
        </div>
        <div *ngIf="value.heading==='Call Recording'">
            <div formGroupName="{{key}}">
                <voiply-call-recording-form-control [submitted]="formSubmitted" formControlName="callRecording"
                    (isHideConfig)=onHideConfig($event)>
                </voiply-call-recording-form-control>
                <mat-tab-group animationDuration="0ms" class="cart-item-background-color"
                    [hidden]="isHideConfiguration===true">
                    <mat-tab label="VoiceMail Settings">
                        <voiply-voicemail-form-control formControlName="voice" insertTemplateVariableFrom="{{key}}"
                            audioCategory="CallRecording" [recordingData]="productConfig"
                            class="cart-item-background-color">
                        </voiply-voicemail-form-control>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div *ngIf="value.heading==='Emergency 999'">
            <div formGroupName="{{key}}">
                <voiply-alert911-form-control formControlName="emergency999"></voiply-alert911-form-control>
            </div>
        </div>
    </div>
    <div class="cart-item-background-color border p-3 d-flex flex-row justify-content-end">
        <button type="submit" class="btn btn-primary text-primary px-4 py-3 font-weight-bold">
            Save Changes
        </button>
    </div>

</form>
