import { Routes } from "@angular/router";
import { RootPageComponent } from './root-page/root-page.component';
import { UserSettingsAuthGuard, CallbackComponent, } from '@voiply/auth0-user-settings';

const routes: Routes = [
    { path: 'callback', component: CallbackComponent },

    { path: ':id', component: RootPageComponent, canActivate: [UserSettingsAuthGuard] },
    { path: ':id/:userId', component: RootPageComponent, canActivate: [UserSettingsAuthGuard] },
    { path: '', component: RootPageComponent, canActivate: [UserSettingsAuthGuard] },


    { path: '**', component: RootPageComponent, canActivate: [UserSettingsAuthGuard] }
];
export const AppRoutes = routes;