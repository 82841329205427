import { Component, OnInit, Input, Inject } from '@angular/core';
import { ProductBase } from '../product.base';
import { ENVIRONMENT, IEnvironment, CrispService, AudioService } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal/';
import * as _ from 'lodash';

@Component({
  selector: 'voiply-product-user-settings',
  templateUrl: './product-user-settings.component.html',
  styleUrls: ['./product-user-settings.component.scss']
})
export class ProductUserSettingsComponent extends ProductBase implements OnInit {
  @Input() userId = '';
  @Input() systemType = '';
  @Input() selectedMatTab = 1
  constructor(@Inject(ENVIRONMENT) environment: IEnvironment, toastr: ToastrService, crispService: CrispService, audioService: AudioService, private modalService: BsModalService) {
    super(environment, toastr, crispService, audioService);
  }


}
