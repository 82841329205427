import { Component, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { ENVIRONMENT, IEnvironment } from '@voiply/shared/common-ui';

@Component({
  selector: 'voiply-root-page',
  templateUrl: './root-page.component.html',
  styleUrls: ['./root-page.component.scss']
})
export class RootPageComponent {
  userId = ''
  systemType = ''
  user = [];
  selectedMatTab = ''
  constructor(@Inject(ENVIRONMENT) private environment: IEnvironment) {
    this.user = JSON.parse(localStorage.getItem('users'));
    this.selectedMatTab = localStorage.getItem('selectedMatTab');
    if
      ((this.user || []).length > 0) {
      this.userId = this.user[0].user_id;
      this.systemType = this.environment.systemType;
    }
  }

}
