import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EmergencyAddress, CartItemType, SystemType } from '@voiply/shared/model';
import { STATES, COUNTRIES, OrderService, UpdateCartItemConfigurationAction, UpdatingPhoneSystemAction, OnDestroyCleanup, FormBase } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from 'libs/shared/common-ui/src/lib/services';
import { FormGroup, FormControl } from '@angular/forms';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { startWith, pairwise } from 'rxjs/operators';
import * as _ from 'lodash';
import { BusinessConfigurationBase } from '../business-configuration.base';

@Component({
  selector: 'voiply-user-settings-business',
  templateUrl: './user-settings-business.component.html',
  styleUrls: ['./user-settings-business.component.scss']
})
export class UserSettingsBusinessComponent extends BusinessConfigurationBase {

  @Input() selectedMatTab = 1;

  constructor(toastr: ToastrService, orderService: OrderService, validateService: ValidationService) {
    super(toastr, orderService, validateService);
  }

  async updateCartItemConfiguration(key, productConfig) {
    this.updateCartItemConfigurationAction(key, productConfig);
    if (this.value.paid) {
      this.updatingPhoneSystem(true);
      await this.orderService.updateOrder(this.orderId,SystemType.Business);
      this.updatingPhoneSystem(false);
    }
  }



}
