import { Component, OnInit } from '@angular/core';
import { AuthService } from '@voiply/auth0-user-settings';
import { AppStateSelectors, OnDestroyCleanup } from '@voiply/shared/common-ui';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CheckoutDetail } from '@voiply/shared/model';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends OnDestroyCleanup implements OnInit {
  userProfile: any = {};
  checkoutDetail: CheckoutDetail;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;

  constructor(private authService: AuthService) {
    super();
    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      this.checkoutDetail = checkoutDetail;
    }))
  }

  ngOnInit() {
    this.getprofile();
  }

  async getprofile() {
    this.userProfile = await this.authService.getProfile(localStorage.getItem('access_token'));
  }

  onLogout() {
    this.authService.logout();
  }


}
