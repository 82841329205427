import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiModule } from '@voiply/shared/common-ui';
import { CONTAINERS } from './container';


@NgModule({
  imports: [CommonModule, CommonUiModule]
  ,
  declarations: [
    ...CONTAINERS,
  ],
  exports: [
    ...CONTAINERS
  ]
})
export class Auth0UserSettingsModule { }
