<!-- VOIPLY SPINNER -->
<div class="center-ring" *ngIf="!childComponentLoaded">
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<!-- VOIPLY SPINNER END -->

<router-outlet (activate)="childComponentLoaded = true"></router-outlet>