<div class="d-flex bg-primary-gradient justify-content-between align-items-center container-fluid"
  style="height: 80px;">
  <div class="d-flex align-items-center">
    <div class="text-white mr-3 d-none d-md-block">Mobile App On</div>
    <div class="d-none d-md-block">
      <img class="mr-2" src="assets/images/playstore.png" alt="" height="30px" />
      <img src="assets/images/appstore.png" alt="" height="30px" />
    </div>
  </div>

  <div>
    <img src="assets/images/logo_white.png" alt="" height="45px" />
  </div>
  <div class="d-flex align-items-center">
    <div class="d-none d-md-block">
      <img class="mr-2" src="assets/images/facebook.png" alt="" height="30px" />
      <img class="mr-2" src="assets/images/linkedin.png" alt="" height="30px" />
      <img class="mr-2" src="assets/images/twitter.png" alt="" height="30px" />
      <img src="assets/images/instagram.png" alt="" height="30px" />
    </div>
    <div class="text-white ml-4 d-none d-md-block"> © 2012-{{currentYear}} VOIPLY LLC ALL RIGHTS RESERVED</div>
  </div>
</div>