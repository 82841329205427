import { Component } from '@angular/core';
import  packageInfo  from '../../../../package.json';

@Component({
  selector: 'voiply-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  childComponentLoaded = false;

  public version: string = packageInfo.version;
  constructor() {
    console.log('VERSION:', packageInfo.version);
  }
}
